<template>

    <ODialog v-model:show="ndsDialog" disableResize size="lg"  :title='$t("Please rate your experience with us")' :disableMovable="false" disableFade
        @hide="onHide">
        <div class="o365-dialog-body d-flex flex-column ">
            <div class="my-3 px-3" >
                <p>
                    {{ $t('As part of our ongoing effort to improve the quality of our services, we would appreciate your feedback. Please take a moment to answer this ONE question:')}}
                </p>

                <h5 class="mt-2">{{ $t('How well does Omega 365 meet your needs?') }}</h5>

                <div class="btn-group d-flex justify-content-evenly" role="group" aria-label="Button group with nested dropdown">
                    <button v-for="rating of scores" type="button" class="btn btn-outline-secondary nps-btn" :class="{ 'active': score == rating}" @click="doRate(rating)">{{ rating }}</button>
                </div>

                <div class="d-flex justify-content-between mt-1 w-100">
                    <span class="me-3 mt-2">{{ ("Not at all") }}</span>
                    <span class="ms-3 mt-2">{{ ("Perfectly") }}</span>
                </div>

            </div>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted } from 'vue';
import { ODialog } from 'o365-ui-components';
import { $t } from 'o365-utils';
import { getOrCreateProcedure } from 'o365-modules';

const scores = Array.from({ length: 11 }, (_, i) => i);

const ndsDialog = ref(false);

const score = ref<number>();

const procRate = getOrCreateProcedure({id : "procAddObjectsToLease", procedureName : "sstp_System_NetPromoterScoresRate"});
const procHide = getOrCreateProcedure({id : "procHideNSP", procedureName : "sstp_System_NetPromoterScoresHide"});

const props = defineProps({
    serverData: Object
});

onMounted(() => {
    ndsDialog.value = true;
});

function onHide(){
    if (!rated) {
        import('o365-modules').then((m) => {
            procHide.execute({
                'Person_ID': m.userSession.personId
            })
        })
    }
}

async function ok(){
    await procRate.execute( {Score:score.value } );
    ndsDialog.value = false;
}

let rated = false;
function doRate(pScore: number){
        score.value = pScore;

    if (score.value != null) {
        rated = true;
        ok();
    }
}


</script>

<style scoped>
    .active{
        background-color: var(--o365-nav-active) !important;
    }

    .nps-btn:hover {
        background-color: var(--o365-nav-active) !important;
    }
    .nps-btn:not(:hover) {
        background-color: white;
    }
</style>